.carousel-center {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .carousel
  .carousel-inner {
    position: relative;
    height: auto;
    max-height: 70vh ;
    display: flex;
  }

  .live-img{
    width: 100px;
  }

  .btn-foro{
    background-color: white !important;
    color: black !important;
  }
