.div-card{
    justify-content: center !important;
}

h1{
    display: flex;
    justify-content: center;
    color: grey;
}

.img-card{
  width: 50% !important;
    /* height: 10%; */
}
.img-info-estado{
  width: 100%;
}

.img-estado{
  display: flex !important;
  justify-content: center !important;
}

.img-card-partido{
  height: 100%;
}
.img-card-partido-jhh{
  width: 100%;
}

.img-partido{
  display: flex;
  height: 10%;
  justify-content: center;
}
.img-partido-jhh{
  display: flex;
  height: 100%;
  justify-content: center;
}

.btnTema {
    background: #F5F5F5 !important;
    color: white !important;
    border-color: #DCDCDC !important;
    border-width: 0.3em !important;
  }
  
  .btnPrincipal {
    background-color: white !important;
    background: white;
    color: gray !important;
    font-weight: bolder !important;
    /* border-radius: 1px !important;
    border-color: #DCDCDC !important; */
    border-width: 0.3em !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .btnPrincipal.clear {
    background-color: white !important;
  }

  /* .active, .btnPrincipal:hover {
    background-color: #666;
    color: white;
  } */



  .modal-semi-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}


.modal.show .modal-dialog {
  transform: none;
  width: 90% !important;
  height: 90% !important;
  margin-top: 20px !important;
  

  margin: auto;
  
  
  
}

.modal.show .modal-dialog .modal-content{
  border-radius: 10px !important;
} 




.image-container .image-overlay {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}


.image-container .middle {

  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.image-container {
  position: relative;
  width: 100%;
}

.image-overlay{

}


.image-container:hover .image-overlay {

  opacity: 0.7;
}

.image-container:hover .middle {
  opacity: 1;
}

.image-container .text {
  background-color: #000000;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.button-voto{
  background: #DADADA !important;
  color: #000000 !important;
  border: #DADADA !important;
}

.button-twitter{
  background: #1DA1F2 !important;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  border: 1px solid #1DA1F2 !important;
  /* padding-left: 22px !important; */
  padding-right: 4px !important;
  padding-top: 2px !important;
}

.button-ya-contesto{
  background: #2F4266 !important;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  border: 1px solid #2F4266 !important;
  /* padding-left: 22px !important; */
  padding-right: 4px !important;
  padding-top: 2px !important;
}




.img-button-ya-contesto{
  width: 60%;
  border-radius: 10px;
  
  
}

.center-banner{
  display: flex;
  justify-content: center;
}

.img-banner-ieem{
  display: flex;
  width: 110%;
  margin-left: -10%;
}

