.haed-center{
    display: flex;
    justify-content: center;
}

.logo-partido{
    width: 100%;
    max-width: 50px;
}

/* .thumb {
    overflow:hidden;
    height: 84px;
} */

.thumb-img {
    width: 100%;
    /* position: relative; */
    /* top: -13px; */
}

.vid-desc {
    width: 100%;
    color: #21A1D2;
    font-size: 15px;
    margin-top:5px;
}