.answer-hidden{
		visibility: visible;
}


.img-cuestionario{
	width: 30%;
}

.candidate-resp-badge-size{
	font-size: 1em !important;
}


.full-height-cuestionario{
	height: 100%;
}

.subquestions{}


.cuestionario-bg{
  position: relative;
}

.cuestionario-bg:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url("../../../../../public/static/banner/BannerAjustado_5.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.container-cuestionario{
   position: relative !important;
}



.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.candidate-img{}

.candidate-row{}

.candidate-answer{}

.success-answer{
  border: 2px solid #28a745;
  padding: 10px;
  border-radius: 10px;
  
}

.danger-answer{
  border: 2px solid #dc3545;

  padding: 10px;
  border-radius: 10px;
   
}

.short-div{
  display: inline-block; 
}