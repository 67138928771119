.text-center{
    display: flex;
    justify-content: center;
}

.text-center-align{
    display: flex;
    justify-content: center;
    align-self: center;
}

.img-center{
    display: flex;
    justify-content: center;
}

.img-right{
    display: flex;
    justify-content: end;
}

.img-modal{
    width: 40%;
}
