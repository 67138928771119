.profile-table{
	height: 100%;
    width:100%;
    border-collapse: collapse;
}

.info-candidato .info-icon{
	width:10;
	height: 10;
	color: silver;
}

.info-candidato .info-label{
	padding-left: 10px;
	color: silver;
	font-size: 0.8em;
}

.info-candidato .info-label2{
	padding-left: 10px;
	font-size: 0.8em;
}

.info-candidato .info-data{
	padding-top: 5px;
	font-size: 0.8em;
}



.numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;

    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    padding: 5px;


}

.nav-link{
    color: #000000 !important;
}
.nav-link.active{
    background: #DADADA !important;
    color: #000000 !important;
    border: #DADADA !important;
  }

.img-infografia{
    width: 70%;
}

.row-center{
    display: flex;
    justify-content: center;
}
.div-vacio{
    width :100%;
    height:400px;
    border : 1px solid black;
}