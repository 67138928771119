.row-bottom{
	padding: 2%;
}

.bottom-section{
	padding: 0;
}

.img-bottom{
	width: 100%;
}

@media (min-width: 992px) {

	.bottom-section{
		padding: 50px;
	}

	.img-bottom{
		width: 70%;
	}
  }

.col-center{
	display: flex;
	justify-content: center;
}