.img-perfil-comite{
    width: 45%;
}

.col-center{
    display: flex;
    justify-content: center;
}

.col-right{
    display: flex;
    justify-content: end;
}

.paragraph-align{
    align-items: center;
}

.col-reverse{
    flex-direction: row-reverse;
}