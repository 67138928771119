.footer-container{
	color: white;
}
.footer-bg{
	background-color: #2F4266;
}

.footer-container a{
	text-decoration: none;
	color: white;
}

.footer-img{
	/* width: 90%; */
	width: 55px;
}

@media (min-width: 992px){
	.border-line {
	  border-left: solid 2px;
	  border-right: solid 2px;
	}
  }