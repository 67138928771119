.img-ve{
    width: 100%;
}

.text-justification{
    text-align: justify;
    text-justify: inter-word;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-position: center;
    background-color: #D7D8DC;
}

.text-bold{
    font-weight: bold;
}

.img-que-necesito{
    width: 100%;
}

.img-center{
    display: flex;
    justify-content: center;
}

.text-align{
    display: flex;
    align-items: center;
}

.card-foro{
    width: 100%;
}

.btn-foros{
    background-color:white !important;
    color: black !important;
}