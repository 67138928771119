.text-justification{
    text-align: justify;
    text-justify: inter-word;
}

.col-center{
    display: flex;
    justify-content: center;
}

.padding-full{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 2%;
}