.img-iniciativa{
    width: 100%;
}

.text-justification{
    text-align: justify;
    text-justify: inter-word;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-position: center;
    background-color: #D7D8DC;
}

.row-mision-vision{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
}

.head-center{
    display: flex;
    justify-content: center;
}

.img-comite{
    width: 30%;
    height: auto;
    margin-top: -5%;
}

.img-center-align{
    display: flex;
    justify-content: center;
}

.shadow-informes{
    width: 50%;
    /* padding-left: 40%;
    padding-right: 40%; */
}