.redes-head{
    display: flex !important;
    justify-content: center;
    padding: 3%;
}

.redes-container{
    display: 'flex' !important;
    justify-content: 'center' !important;
    /* max-height:'510'; */
    max-width: '500' !important;
    /* width={500} height={510} */
    border:'none';
    overflow:'hidden';
}

.videowrapper { 
    float: none;
    clear: both;
    width: 700px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 700px;
    margin: 0 auto;
    max-width: 100%;
}